<template>
  <div class="documentation">
    <h1 class="title">
      Documentation
    </h1>
    <p>
      Multiple Sclerosis simulations are performed with a Multiple Sclerosis extension of the Universal Immune System Simulator
      <span>(Pappalardo, Russo & Pennisi et al, 2020)</span>.
      The underlying agent-based model simulates the immune system at the individual level and incorporates both the innate and acquired immune systems. The simulator takes as input base patient characteristics like age, disease severity and treatment history, and can then produce realistic disease courses.
    </p>
    <div class="with-space">
      <h2 class="title is-4">
        Publications
      </h2>
      <ul>
        <li class="with-space">
          <span>
            The Potential of Computational Modeling to Predict Disease Course and Treatment Response in Patients with Relapsing Multiple Sclerosis
          </span>
          <br>Pappalardo, F., Russo, G., Pennisi, M., et al.
          Cells. 9, 586.
          <b>2020</b>
          <br>
          DOI:
          <a
            href="https://doi.org/10.3390/cells9030586"
            target="_blank"
            rel="noopener noreferrer"
          >
            10.3390/cells9030586
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
div.documentation {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
