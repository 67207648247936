<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse>
                  <p>
                    MS TreatSim creates realistic virtual representations of Multiple Sclerosis patients, based on a detailed mechanistic model of the underlying immune system and its dysregulation in Multiple Sclerosis. Simulations can be done both at the population level (in silico trial workflow), or at the individual level (digital twin workflow). For instructions on how to use the product, please refer to the user manual (download here) and the guidance found in the workflow.
                  </p>
                  <ul style="margin-top: 0.75em;">
                    <li>
                      <a
                        :href="`${publicPath}MS_TreatSim_User_Manual.pdf`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >User Manual
                      </a>
                    </li>
                  </ul>
                </HowToUse>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HowToUse from 'ist-skeleton-vue/src/components/Home/HowToUse.vue';
import LatestActivity from 'ist-skeleton-vue/src/components/Home/LatestActivity.vue';
import NewSimulation from 'ist-skeleton-vue/src/components/Home/NewSimulation.vue';
import Support from 'ist-skeleton-vue/src/components/Home/Support.vue';
import Documentation from '../components/Documentation.vue';

export default {
  components: {
    Documentation,
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
};
</script>
