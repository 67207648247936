import image from '../assets/product.jpg';

const product = {
  id: 'mstreat',
  name: 'MS TreatSim',
  title: 'Multiple Sclerosis Treatment Simulator',
  subtitle: 'Simulations of Relapsing Remitting Multiple Sclerosis and its Treatment',
  description: 'A unique tool for quantitative prediction of relapse rate and immune dynamics under treatment in virtual Relapsing-Remitting Multiple Sclerosis patients',
  collaboration: 'MS TreatSim is the result of a collaboration between Mimesis Srl and InSilicoTrials Technologies',
  image,
  features: [
    {
      icon: 'cogs',
      title: 'Mechanism-based simulation',
      description: 'Simulations embody the innate and adaptive immune system, the auto-immune reaction, patient heterogeneity and treatment effects',
    },
    {
      icon: 'clipboard-check',
      title: 'Validated outcome',
      description: 'Simulations of relapse rates were validated with individual clinical data and clinical trial results',
    },
    {
      icon: 'thumbs-up',
      title: 'Easy to use wizard',
      description: 'Friendly step-by-step simulation workflow that guides simulation setup, run, results visualization and download',
    },
  ],
};

export default product;
