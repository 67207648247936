<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title">
          <Loader :is-loading="true" />
          Results
        </h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName
            :name="job.name"
            @jobNameEdit="handleJobNameEdit"
          />
          <ReportModal
            v-if="job.status == 'completed' && task.status === 'success' && (isWorkflowTrial || isWorkflowPrediction)"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </div>
        <h1
          class="title no-space-bottom"
          data-cy="job-title"
        >
          {{ job.name }}
        </h1>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ $filters.formatDate(task.started_at) }}</p>
            </div>
            <div class="level-item">
              <p>completed: {{ $filters.formatDate(task.finished_at) }}</p>
            </div>
            <div class="level-item">
              <span
                class="tag is-medium"
                :class="{ 'is-warning': task.status !== 'success' }"
                data-cy="task-status"
              >{{ task.status }}</span>
            </div>
          </div>
        </div>
        <InputsSummary
          v-if="input != null"
        >
          <div style="margin-bottom: 2em;">
            <SummaryItem
              v-for="(element, index) in getSummaryItems"
              :key="index"
              :config="element"
            />
          </div>
          <hr>
        </InputsSummary>
        <template v-if="job.status == 'completed' && task.status === 'success' && isWorkflowTrial">
          <h2 class="title is-4">
            Trial results
          </h2>
          <p>
            Averages are expressed as mean ± SD.
          </p>
          <h3 class="title title-with-small-margin is-5">
            Patients’ population baseline characteristics
          </h3>
          <div class="table-container">
            <table
              v-if="output != null && output.user_input != null && output.user_input.trial_design != null && output.tables != null && output.tables.inputs != null"
              class="table ist-table is-striped"
              style="margin-top: 1.5em;"
              data-cy="baseline-characteristics-table"
            >
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th
                    v-for="(group, index) in output.user_input.trial_design"
                    :key="index"
                  >
                    {{ group.trial_design_group_name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(element, index) in output.tables.inputs"
                  :key="index"
                >
                  <td>{{ element.name }}</td>
                  <td>{{ element.category }}</td>
                  <td>{{ element.unit }}</td>
                  <td
                    v-for="(group, index2) in element.groups"
                    :key="index2"
                  >
                    {{ group.val || ( group.val_mean + " ± " + group.val_std) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="title title-with-margin is-5">
            In silico trial outcomes
          </h3>
          <div class="tabs is-toggle is-centered tabs-with-space">
            <ul
              v-if="endpoints != null"
            >
              <li
                v-for="(element, index) in endpoints"
                :key="index"
                :class="{ 'is-active': activeEndpoint === element.id }"
              >
                <a
                  :id="element.id"
                  @click="activeEndpoint = element.id"
                >
                  {{ element.label }}
                </a>
              </li>
            </ul>
          </div>
          <div class="table-container">
            <table
              v-if="endpoints != null"
              class="table ist-table is-striped"
              style="margin-top: 1.5em;"
              data-cy="endpoint-table"
            >
              <thead>
                <tr>
                  <th />
                  <th />
                  <th
                    v-for="(group, index) in output.user_input.trial_design"
                    :key="index"
                    :colspan="group.trial_design_treatments.length"
                  >
                    {{ group.trial_design_group_name }}
                  </th>
                </tr>
                <tr class="table-second-header">
                  <th />
                  <th />
                  <template
                    v-for="(group, index) in output.user_input.trial_design"
                    :key="index"
                  >
                    <th
                      v-for="(treat, index2) in group.trial_design_treatments"
                      :key="index2"
                    >
                      {{ getTreatmentLabel(treat) }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(element, index) in getEndpointTable.rows"
                  :key="index"
                >
                  <td>{{ element.name }}</td>
                  <td>{{ element.unit }}</td>
                  <template
                    v-for="(group, index2) in element.groups"
                    :key="index2"
                  >
                    <td
                      v-for="(treat, index3) in group.treatments"
                      :key="index3"
                    >
                      {{ treat.val || ( treat.val_mean + " ± " + treat.val_std) }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="title title-with-margin is-5">
            Time to relapse
            <FieldHelp
              :text="tooltip.trial.time_to_relapse"
            />
          </h3>
          <div class="tabs is-toggle is-centered tabs-with-space">
            <ul
              v-if="relapseTimes != null"
            >
              <li
                v-for="(element, index) in relapseTimes"
                :key="index"
                :class="{ 'is-active': activeRelapseTime === element.id }"
              >
                <a
                  :id="element.id"
                  @click="activeRelapseTime = element.id"
                >
                  {{ element.label }}
                </a>
              </li>
            </ul>
          </div>
          <figure
            v-if="getRelapseTimeFigure"
            class="image figure-with-space"
          >
            <img
              :src="resultsURL(getRelapseTimeFigure)"
              data-cy="relapse-time-figure"
            >
          </figure>
          <h3 class="title title-with-margin is-5">
            Relapse and immune system dynamics
          </h3>
          <p>
            Relapse dynamics (expressed as lesion activity, which is calculated as the relative reduction in oligodendrocytes) and mean values of cytokine, leukocyte and antibody levels during the trial.
          </p>
          <div class="tabs is-toggle is-centered tabs-with-space">
            <ul
              v-if="relapseDynamics != null"
            >
              <li
                v-for="(element, index) in relapseDynamics"
                :key="index"
                :class="{ 'is-active': activeRelapseDynamic === element.id }"
              >
                <a
                  :id="element.id"
                  @click="activeRelapseDynamic = element.id"
                >
                  {{ element.label }}
                </a>
              </li>
            </ul>
          </div>
          <template
            v-if="getRelapseDynamicFigures"
          >
            <figure
              v-for="(element, index) in getRelapseDynamicFigures"
              :key="index"
              class="image figure-with-space"
            >
              <img
                :src="resultsURL(element)"
                data-cy="relapse-dynamic-figure"
              >
            </figure>
          </template>
          <p class="buttons">
            <a
              class="button is-primary"
              style="margin-right: 0.25em;"
              :href="resultsURL('in_silico_trial_results.xlsx')"
              target="_blank"
              download
              data-cy="results-download"
            >
              <font-awesome-icon
                class="icon-with-margin"
                icon="download"
              />
              Results
            </a>
          </p>
        </template>
        <template v-if="job.status == 'completed' && task.status === 'success' && isWorkflowCalibration">
          <h2 class="title is-4">
            Calibration results
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldSelect
                :id="virtualPatient.id"
                v-model="virtualPatientModel"
                :options="virtualPatient.options"
                :label="virtualPatient.label"
              />
            </div>
          </div>
          <h3 class="title is-5">
            Virtual patient
          </h3>
          <p>
            To make a prediction of a patient’s response to therapy, a virtual patient that closely reproduces the true patient’s clinical history must be selected from the calibration set. For this purpose, the location of true relapses with confirmed increase of lesion load (blue bands) and the location of simulated relapses (dots, representing lesion activity) are plotted together in the figure shown below. The virtual patients within a set are ranked according to minimal Euclidian distances between simulated and true relapses, but final selection of the best virtual patient is performed by the user. Lesion activity is calculated as oligodendrocyte loss.
          </p>
          <figure
            v-if="virtualPatientFigure"
            class="image figure-with-space"
          >
            <img
              :src="virtualPatientFigure"
              data-cy="virtual-patient-figure"
            >
          </figure>
        </template>
        <template v-if="job.status == 'completed' && task.status === 'success' && isWorkflowPrediction">
          <h2 class="title is-4">
            Prediction results
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldSelect
                :id="treatment.id"
                v-model="treatmentModel"
                :options="treatment.options"
                :label="treatment.label"
              />
            </div>
          </div>
          <h3 class="title is-5">
            Graph types
          </h3>
          <p>
            In the Relapses figure, the top panel shows both virtual patient history (left) and predicted relapses (right, in red). The bottom panel summarizes the treatment and relapse history entered by the user. The cytokines figures shows the associated IL-2, IL-12, TGF-β and IFN-γ dynamics. The leukocytes figure shows the dynamics of oligodendrocytes, as well as of various white blood cell populations. Finally, the Ig figure illustrates the dynamics of the simulated antibodies. In all figures, the dotted line denotes the reference date, which divides history and prediction.
          </p>
          <div class="tabs is-toggle is-centered tabs-with-space">
            <ul>
              <li
                v-for="(element, index) in graphTypes"
                :key="index"
                :class="{ 'is-active': activeGraphType === element.id }"
              >
                <a
                  :id="element.id"
                  @click="activeGraphType = element.id"
                >
                  {{ element.label }}
                </a>
              </li>
            </ul>
          </div>
          <figure
            v-if="getGraphTypeFigure"
            class="image figure-with-space"
          >
            <img
              :src="resultsURL(getGraphTypeFigure)"
              data-cy="graph-type-figure"
            >
          </figure>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import EditJobName from 'ist-skeleton-vue/src/components/Results/EditJobName.vue';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import FieldSelect from 'ist-skeleton-vue/src/components/Simulation/FieldSelect.vue';
import InputsSummary from 'ist-skeleton-vue/src/components/Results/InputsSummary.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import ReportModal from 'ist-skeleton-vue/src/components/Results/ReportModal.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';

export default {
  components: {
    EditJobName,
    FieldHelp,
    FieldSelect,
    InputsSummary,
    ReportModal,
    SummaryItem,
    Loader,
  },
  data() {
    return {
      // job data
      job: null,
      task: null,
      input: null,
      output: null,
      summaryItems: null,
      // media data
      baseUrl: null,
      containerSAS: null,
      calibrationBaseUrl: null,
      calibrationContainerSAS: null,
      // report
      isPdfLoading: false,
      // tooltip (help)
      tooltip: {
        trial: {
          time_to_relapse: 'Under the tab “between groups” survival curves are shown for all simulated groups in a single figure. The following tabs provide the survival curves per group.',
        },
      },
      // workflow trial
      endpoints: null,
      activeEndpoint: null,
      relapseTimes: null,
      activeRelapseTime: null,
      relapseDynamics: null,
      activeRelapseDynamic: null,
      // workflow calibration
      virtualPatient: {
        id: 'virtual_patient',
        label: 'Virtual patient',
        type: 'select',
        options: [],
      },
      virtualPatientModel: null,
      virtualPatientFigure: null,
      // workflow prediction
      calibration: null,
      dataset: null,
      treatment: {
        id: 'treatment',
        label: 'Treatment',
        type: 'select',
        options: [],
      },
      treatmentModel: null,
      graphTypes: null,
      activeGraphType: null,
    };
  },
  computed: {
    isWorkflowTrial() {
      return this.input != null && this.input.workflow != null && this.input.workflow === 'trial';
    },
    isWorkflowCalibration() {
      return this.input != null && this.input.workflow != null && this.input.workflow === 'calibration';
    },
    isWorkflowPrediction() {
      return this.input != null && this.input.workflow != null && this.input.workflow === 'prediction';
    },
    getEndpointTable() {
      if (this.endpoints != null) {
        return this.endpoints.find((element) => element.id === this.activeEndpoint);
      }
      return null;
    },
    getRelapseTimeFigure() {
      if (this.relapseTimes != null && this.activeRelapseTime != null) {
        const relapseTime = this.relapseTimes.find((element) => element.id === this.activeRelapseTime);
        return relapseTime.figure;
      }
      return null;
    },
    getRelapseDynamicFigures() {
      if (this.relapseDynamics != null && this.activeRelapseDynamic != null) {
        const relapseDynamic = this.relapseDynamics.find((element) => element.id === this.activeRelapseDynamic);
        return relapseDynamic.figures;
      }
      return null;
    },
    getGraphTypeFigure() {
      if (this.graphTypes != null && this.activeGraphType != null) {
        const graphType = this.graphTypes.find((element) => element.id === this.activeGraphType);
        return graphType.figure;
      }
      return null;
    },
    getSummaryItems() {
      const inputs = [];
      if (this.input != null) {
        if (this.isWorkflowTrial) {
          inputs.push(
            {
              id: 'lesion_load',
              label: 'Lesion load',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.lesion_load,
                [
                  { value: 'high', label: 'High lesion load' },
                  { value: 'low', label: 'Medium / low lesion load' },
                  { value: 'default', label: '50 % chance of high lesion load' },
                ],
              ),
            },
          );
          inputs.push(
            {
              id: 'oligoclonal_bands',
              label: 'Oligoclonal bands',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.oligoclonal_bands,
                [
                  { value: 'yes', label: 'Oligoclonal bands present' },
                  { value: 'no', label: 'Oligoclonal bands not present' },
                  { value: 'default', label: '90 % chance of oligoclonal bands presence' },
                ],
              ),
            },
          );
          inputs.push(
            {
              id: 'age_of_onset_type',
              label: 'Age of onset (years)',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.age_of_onset_type,
                [
                  { value: 'default', label: 'Use default age distribution' },
                  { value: 'custom', label: 'Define custom age distribution' },
                ],
              ),
            },
          );
          if (this.input.age_of_onset_ratio != null && this.input.age_of_onset_ratio.length === 3) {
            inputs.push(
              {
                id: 'age_of_onset_ratio_first',
                label: 'Ages 18-29 (ratio)',
                type: 'result',
                value: this.input.age_of_onset_ratio[0],
              },
            );
            inputs.push(
              {
                id: 'age_of_onset_ratio_second',
                label: 'Ages 30-39 (ratio)',
                type: 'result',
                value: this.input.age_of_onset_ratio[1],
              },
            );
            inputs.push(
              {
                id: 'age_of_onset_ratio_third',
                label: 'Ages 40-49 (ratio)',
                type: 'result',
                value: this.input.age_of_onset_ratio[2],
              },
            );
          }
          inputs.push(
            {
              id: 'duration_min',
              label: 'Disease duration - minimum (years)',
              type: 'result',
              value: this.input.duration_min,
            },
          );
          inputs.push(
            {
              id: 'duration_max',
              label: 'Disease duration - maximum (years)',
              type: 'result',
              value: this.input.duration_max,
            },
          );
          inputs.push(
            {
              id: 'activity_criteria',
              label: 'Disease activity',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.activity_criteria,
                [
                  { value: 'month', label: 'No relapse in 30 days prior to trial start' },
                  { value: 'halfyear', label: 'At least 1 relapse in 6 months prior to trial start' },
                  { value: 'year', label: 'At least 1 relapse in year prior to trial start' },
                  { value: 'twoyears', label: 'At least 2 relapses in 2 years prior to trial start' },
                  { value: 'yearORtwoyears', label: 'At least 1 in 1 year, or 2 in 2 years' },
                ],
              ),
            },
          );
          if (this.input.trial_design != null && this.input.trial_design.length > 0) {
            this.input.trial_design.forEach((element) => {
              inputs.push(
                {
                  id: 'trial_design_group_name',
                  label: 'Virtual group name',
                  type: 'result',
                  value: element.trial_design_group_name,
                },
              );
              inputs.push(
                {
                  id: 'trial_design_group_size',
                  label: 'Size',
                  type: 'result',
                  value: element.trial_design_group_size,
                },
              );
              inputs.push(
                {
                  id: 'trial_design_treatments',
                  label: 'Treatment(s)',
                  type: 'result',
                  value: this.getSummaryItemLabel(
                    element.trial_design_treatments,
                    [
                      { value: 'naive', label: 'Placebo' },
                      { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
                      { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
                      { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
                      { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
                      { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
                      { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
                    ],
                  ),
                },
              );
            });
          }
          inputs.push(
            {
              id: 'trial_length',
              label: 'Total trial duration (weeks)',
              type: 'result',
              value: this.input.trial_length,
            },
          );
          if (this.input.interim != null && this.input.interim.length > 0) {
            this.input.interim.forEach((element) => {
              inputs.push(
                {
                  id: 'interim',
                  label: 'Perform interim analysis at (weeks)',
                  type: 'result',
                  value: element.interim,
                },
              );
            });
          }
        } else if (this.isWorkflowCalibration) {
          inputs.push(
            {
              id: 'date_of_birth',
              label: 'Date of birth',
              type: 'result',
              value: this.input.date_of_birth,
            },
          );
          inputs.push(
            {
              id: 'date_of_onset',
              label: 'Date of onset',
              type: 'result',
              value: this.input.date_of_onset,
            },
          );
          inputs.push(
            {
              id: 'date_reference',
              label: 'Reference date',
              type: 'result',
              value: this.input.date_reference,
            },
          );
          inputs.push(
            {
              id: 'oligoclonal_bands',
              label: 'Oligoclonal bands',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.oligoclonal_bands,
                [
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                  { value: 'unknown', label: 'Unknown' },
                ],
              ),
            },
          );
          inputs.push(
            {
              id: 'lesion_load',
              label: 'Lesion load',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.lesion_load,
                [
                  { value: 'high', label: 'High' },
                  { value: 'medium-low', label: 'Medium / low' },
                  { value: 'unknown', label: 'Unknown' },
                ],
              ),
            },
          );
          if (this.input.relapses != null && this.input.relapses.length > 0) {
            this.input.relapses.forEach((element) => {
              inputs.push(
                {
                  id: 'relapse_date',
                  label: 'Date of relapse',
                  type: 'result',
                  value: element.relapse_date,
                },
              );
              inputs.push(
                {
                  id: 'relapse_lesion',
                  label: 'Increase of lesion number (MRI)',
                  type: 'result',
                  value: element.relapse_lesion,
                },
              );
            });
          }
          if (this.input.treatment_history != null && this.input.treatment_history.length > 0) {
            this.input.treatment_history.forEach((element) => {
              inputs.push(
                {
                  id: 'treatment_type',
                  label: 'Treatment type',
                  type: 'result',
                  value: this.getSummaryItemLabel(
                    element.treatment_type,
                    [
                      { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
                      { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
                      { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
                      { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
                      { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
                      { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
                    ],
                  ),
                },
              );
              inputs.push(
                {
                  id: 'treatment_first',
                  label: 'First treatment',
                  type: 'result',
                  value: element.treatment_first,
                },
              );
              inputs.push(
                {
                  id: 'treatment_final',
                  label: 'Final treatment',
                  type: 'result',
                  value: element.treatment_final,
                },
              );
            });
          }
          inputs.push(
            {
              id: 'repeats',
              label: 'Number of virtual patients',
              type: 'result',
              value: this.input.repeats,
            },
          );
        } else if (this.isWorkflowPrediction) {
          inputs.push(
            {
              id: 'dataset',
              label: 'Calibration dataset',
              type: 'result',
              value: this.dataset,
            },
          );
          inputs.push(
            {
              id: 'vp_num',
              label: 'Virtual patient',
              type: 'result',
              value: this.input.vp_num,
            },
          );
          inputs.push(
            {
              id: 'length_of_simulation',
              label: 'Length of simulation (years)',
              type: 'result',
              value: this.input.length_of_simulation,
            },
          );
          inputs.push(
            {
              id: 'scenarios',
              label: 'Treatment options',
              type: 'result',
              value: this.getSummaryItemLabel(
                this.input.scenarios,
                [
                  { value: 'naive', label: 'No treatment' },
                  { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
                  { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
                  { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
                  { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
                  { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
                  { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
                ],
              ),
            },
          );
        }
      }
      return inputs;
    },
  },
  watch: {
    /* eslint-disable-next-line no-unused-vars */
    virtualPatientModel(newObj, oldObj) {
      // console.debug('watch (virtualPatientModel)', newObj, oldObj);
      this.setVirtualPatientFigure(newObj.value);
    },
    /* eslint-disable-next-line no-unused-vars */
    treatmentModel(newObj, oldObj) {
      // console.debug('watch (treatmentModel)', newObj, oldObj);
      this.setGraphTypes(newObj.value);
    },
  },
  async mounted() {
    try {
      await this.fetchJob();
      await this.fetchMediaInfo();
      if (this.task.status === 'success') {
        this.output = await this.fetchOutput();
        if (this.isWorkflowTrial) {
          this.setWorkflowTrial();
        } else if (this.isWorkflowCalibration) {
          this.setWorkflowCalibration();
        } else if (this.isWorkflowPrediction) {
          this.setWorkflowPrediction();
        }
        // console.debug('input', this.input);
        // console.debug('output', this.output);
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchJob() {
      const response = await this.$apollo.query({
        query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              status
              created_at
              tasks {
                id: _id
                input
                status
                started_at
                finished_at
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
        },
      });
      // console.debug(response.data);
      this.job = response.data.job;
      [this.task] = this.job.tasks;
      this.input = this.task.input;
    },
    async fetchCalibration() {
      if (this.input != null && this.input.dataset != null) {
        const response = await this.$apollo.query({
          query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              tasks {
                id: _id
                input
              }
            }
          }
        `,
          variables: {
            product_name: this.$product.id,
            job_id: this.input.dataset.split('-')[0],
          },
        });
        const { job } = response.data;
        this.dataset = job.name;
        [this.calibration] = job.tasks;
      }
    },
    async fetchMediaInfo() {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      this.baseUrl = response.data.info.baseURL;
      this.containerSAS = response.data.info.containerSAS;
    },
    resultsURL(filename) {
      return `${this.baseUrl}${filename}?${this.containerSAS}`;
    },
    async fetchCalibrationMediaInfo(calibrationJobId, calibrationTaskId) {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: calibrationJobId,
          task_id: calibrationTaskId,
        },
      });
      this.calibrationBaseUrl = response.data.info.baseURL;
      this.calibrationContainerSAS = response.data.info.containerSAS;
    },
    calibrationResultsURL(filename) {
      return `${this.calibrationBaseUrl}${filename}?${this.calibrationContainerSAS}`;
    },
    async fetchOutput() {
      const filename = 'output.json';
      const url = this.resultsURL(filename);
      const response = await fetch(url);
      return response.json();
    },
    setWorkflowTrial() {
      if (this.output != null) {
        // endpoints
        if (this.output.tables != null && this.output.tables.outcomes != null) {
          this.endpoints = [];
          this.output.tables.outcomes.forEach((element, index) => {
            this.endpoints.push({ id: `endpoint-${index}`, label: element.id, rows: element.rows });
          });
          this.activeEndpoint = this.endpoints[0].id;
        }
        // relapseTimes
        if (this.output.user_input != null && this.output.user_input.trial_design != null) {
          this.relapseTimes = [];
          const figure = this.output.figures != null && this.output.figures.survival != null && this.output.figures.survival[0] != null ? this.output.figures.survival[0] : null;
          this.relapseTimes.push({ id: 'relapse-time-0', label: 'between groups', figure });
          this.activeRelapseTime = this.relapseTimes[0].id;
          this.output.user_input.trial_design.forEach((element, index) => {
            const figureUrl = this.output.figures != null && this.output.figures.survival != null && this.output.figures.survival[index + 1] != null ? this.output.figures.survival[index + 1] : null;
            this.relapseTimes.push({
              id: `relapse-time-${index + 1}`, label: `within ${element.trial_design_group_name}`, figure: figureUrl, caption: element.trial_design_group_name,
            });
          });
        }
        // relapseDynamics
        if (this.output.user_input != null && this.output.user_input.trial_design != null) {
          this.relapseDynamics = [];
          this.relapseDynamics.push({
            id: 'relapses-individual', label: 'Individual relapses', figures: this.output.figures != null && this.output.figures.individual != null ? this.output.figures.individual : null, caption: 'Relapse activity during trial for individual virtual patients',
          });
          this.relapseDynamics.push({
            id: 'relapses-group', label: 'Relapses by group', figures: this.output.figures != null && this.output.figures.time_dynamics != null ? this.output.figures.time_dynamics : null, caption: 'Lesion activity per treatment regimen and group. Lesion activity is shown for individual virtual patients, which are represented by unique colors',
          });
          this.relapseDynamics.push({
            id: 'cytokines', label: 'Cytokines', figures: this.output.figures != null && this.output.figures.bubbles != null ? this.output.figures.bubbles.find((element) => element.id === 'cytokines').figures : null, caption: 'Mean cytokine concentrations over the trial for individual virtual patients. The size of the markers is determined by the mean lesion activity of the virtual patient over the trial - i.e., the larger the marker, the more relapse activity the virtual patient displays',
          });
          this.relapseDynamics.push({
            id: 'leukocytes', label: 'Leukocytes', figures: this.output.figures != null && this.output.figures.bubbles != null ? this.output.figures.bubbles.find((element) => element.id === 'leukocyte').figures : null, caption: 'Mean leukocyte concentrations over the trial for individual virtual patients. The size of the markers is determined by the mean lesion activity of the virtual patient over the trial - i.e., the larger the marker, the more relapse activity the virtual patient displays',
          });
          this.relapseDynamics.push({
            id: 'antibodies', label: 'Antibodies', figures: this.output.figures != null && this.output.figures.bubbles != null ? this.output.figures.bubbles.find((element) => element.id === 'antibodies').figures : null, caption: 'Mean antibody concentrations over the trial for individual virtual patients. The size of the markers is determined by the mean lesion activity of the virtual patient over the trial - i.e., the larger the marker, the more relapse activity the virtual patient displays',
          });
          this.activeRelapseDynamic = this.relapseDynamics[0] != null ? this.relapseDynamics[0].id : null;
        }
      }
    },
    setWorkflowCalibration() {
      if (this.output != null) {
        // virtualPatient
        this.output.forEach((element, index) => {
          if (index === 0) {
            this.virtualPatientModel = { value: element.figure_name, ok: true };
          }
          this.virtualPatient.options.push({ value: element.figure_name, label: element.rs.toString() });
        });
      }
    },
    setVirtualPatientFigure(filename) {
      this.virtualPatientFigure = null;
      if (filename != null) {
        this.virtualPatientFigure = this.resultsURL(filename);
      }
    },
    async setWorkflowPrediction() {
      if (this.input != null && this.input.dataset != null) {
        // calibration
        await this.fetchCalibration();
      }
      if (this.output != null && this.output.figures != null) {
        // treatment
        this.output.figures.forEach((element, index) => {
          if (index === 0) {
            this.treatmentModel = { value: element.scenario_type, ok: true };
          }
          this.treatment.options.push({ value: element.scenario_type, label: this.getTreatmentLabel(element.scenario_type) });
        });
      }
    },
    getTreatmentLabel(treatmentId) {
      let result = '';
      switch (treatmentId) {
        case 'naive':
          result = 'Placebo';
          break;
        case 'ifnb1a-22':
          result = 'IFN-β1a - 22 mcg, 3 times a week';
          break;
        case 'ifnb1a-44':
          result = 'IFN-β1a - 44 mcg, 3 times a week';
          break;
        case 'terif':
          result = 'Teriflunomide - 14 mg, QD';
          break;
        case 'natal-4wks':
          result = 'Natalizumab - 300 mg, every 4 weeks';
          break;
        case 'natal-6wks':
          result = 'Natalizumab - 300 mg, every 6 weeks';
          break;
        case 'ocrel':
          result = 'Ocrelizumab - 600 mg, every 6 months';
          break;
        default:
          break;
      }
      return result;
    },
    getTreatmentsByReport(treatments) {
      const ret = [];
      treatments.forEach((x) => {
        if (x === 'naive') {
          ret.push('placebo');
        } else {
          ret.push(x);
        }
      });
      return ret;
    },
    setGraphTypes(treatmentId) {
      if (this.output != null && this.output.figures != null) {
        this.graphTypes = null;
        const treatment = this.output.figures.find((element) => element.scenario_type === treatmentId);
        if (treatment != null) {
          this.graphTypes = [];
          this.graphTypes.push({ id: 'relapses', label: 'Relapses', figure: treatment.relapses });
          this.graphTypes.push({ id: 'ck', label: 'Cytokines', figure: treatment.ck });
          this.graphTypes.push({ id: 'lk', label: 'Leukocytes', figure: treatment.lk });
          this.graphTypes.push({ id: 'ig', label: 'Ig', figure: treatment.ig });
          this.activeGraphType = 'relapses';
        }
      }
    },
    getSummaryItemLabel(value, options) {
      let ret = '';
      if (value != null && options != null) {
        if (Array.isArray(value)) {
          ret = [];
          value.forEach((val) => {
            const find = options.find((element) => element.value === val);
            if (find != null && find.label != null) {
              ret.push(find.label);
            }
          });
        } else {
          const find = options.find((element) => element.value === value);
          if (find != null && find.label != null) {
            ret = find.label;
          }
        }
      }
      return ret;
    },
    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },
    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // refresh token for images
      await this.fetchMediaInfo();
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });
      const summaryItems = this.getSummaryItems;
      const reportTemplate = `mstreat-${this.input.workflow}`;
      let reportTokens = null;
      if (this.isWorkflowTrial) {
        let interims = [];
        if (this.input.interim != null && this.input.interim.length > 0) {
          interims = this.input.interim.map((x) => ({ type: 'string', data: `Checkpoint at (weeks): ${x.interim}` }));
        }
        // prepare tables
        const trialDesignTable = {
          header: ['Group', 'Group name', 'Group size', 'Treatments'],
          caption: 'Trial design',
          rows: this.output.user_input.trial_design.map((x, index) => [index, x.trial_design_group_name, x.trial_design_group_size, this.getTreatmentsByReport(x.trial_design_treatments).join(', ')]),
        };
        const trialOutcomesTables = [];
        this.endpoints.forEach((endpoint) => {
          this.output.user_input.trial_design.forEach((td, index) => {
            trialOutcomesTables.push({
              title: index === 0 ? `### ${endpoint.label}` : null,
              header: ['', '', ...this.getTreatmentsByReport(td.trial_design_treatments)],
              rows: endpoint.rows.map((x) => [x.name, x.unit, ...x.groups[index].treatments.map((y) => y.val || (`${y.val_mean} ± ${y.val_std}`))]),
              caption: `${endpoint.label} - ${td.trial_design_group_name}`,
            });
          });
        });
        // prepare plots
        const relapseTimesFigures = [];
        this.relapseTimes.forEach((x) => {
          relapseTimesFigures.push({
            title: `### ${this.capitalise(x.label)}`,
            url: this.resultsURL(`${x.figure}`),
            caption: x.caption == null ? 'Kaplan-Meier curves for all treatment regimens in all groups' : `Kaplan-Meier curves for all treatment regimens in ${x.caption}`,
            limitHeight: true,
          });
        });
        const relapseDynamicsFigures = [];
        this.relapseDynamics.forEach((x) => {
          x.figures.forEach((fig, index) => {
            relapseDynamicsFigures.push({
              title: index === 0 ? `### ${x.label}` : null,
              url: this.resultsURL(`${fig}`),
              caption: `${x.caption}`,
              limitHeight: true,
            });
          });
        });
        // report
        reportTokens = [
          ...userContentTokens,
          { key: 'title', type: 'string', data: this.job.name },
          { key: 'subtitle', type: 'string', data: `${this.$product.name} - In Silico Trial Report` },
          { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
          {
            key: 'treatment_legend_table',
            type: 'table',
            data: {
              header: ['Abbreviation', 'Treatment'],
              caption: 'Treatment abbreviation legend',
              rows: [
                ['placebo', 'Placebo'],
                ['ifnb1a-22', 'IFN-$\\beta 1 \\mathrm{a}$ - 22 mcg, 3 times a week'], // IFN-β1a ...
                ['ifnb1a-44', 'IFN-$\\beta 1 \\mathrm{a}$ - 44 mcg, 3 times a week'], // IFN-β1a ...
                ['terif', 'Teriflunomide - 14 mg, QD'],
                ['natal-4wks', 'Natalizumab - 300 mg, every 4 weeks'],
                ['natal-6wks', 'Natalizumab - 300 mg, every 6 weeks'],
                ['ocrel', 'Ocrelizumab - 600 mg, every 6 months'],
              ],
            },
          },
          {
            key: 'population_definition_stack',
            type: 'stack',
            data: [
              { type: 'string', data: `Lesion load: ${(summaryItems.find((x) => x.id === 'lesion_load')).value}` },
              { type: 'string', data: `Oligoclonal bands: ${(summaryItems.find((x) => x.id === 'oligoclonal_bands')).value}` },
              { type: 'string', data: `Age of onset (years): ${(summaryItems.find((x) => x.id === 'age_of_onset_type')).value}` },
              { type: 'string', data: this.input.age_of_onset_ratio != null && this.input.age_of_onset_ratio.length === 3 ? `Age of onset ratios: [${this.input.age_of_onset_ratio}]` : 'Age of onset ratios: [49.9, 36.2, 14.4]' },
              { type: 'string', data: `Minimal disease duration prior to trial (years): ${this.input.duration_min}` },
              { type: 'string', data: `Maximal disease duration prior to trial (years): ${this.input.duration_max}` },
              { type: 'string', data: `Activity criteria: ${(summaryItems.find((x) => x.id === 'activity_criteria')).value.join(', ')}` },
            ],
          },
          { key: 'trial_design_table', type: 'table', data: trialDesignTable },
          {
            key: 'trial_timeline_stack',
            type: 'stack',
            data: [
              { type: 'string', data: `Trial duration (weeks): ${this.input.trial_length}` },
              ...interims,
            ],
          },
          {
            key: 'baseline_characteristics_table',
            type: 'table',
            data: {
              header: ['', '', '', ...this.output.user_input.trial_design.map((x) => x.trial_design_group_name)],
              caption: 'Baseline characteristics',
              rows: this.output.tables.inputs.map((x) => [x.name, x.category, x.unit, ...x.groups.map((y) => y.val || (`${y.val_mean} ± ${y.val_std}`))]),
            },
          },
          { key: 'trial_outcomes_tables', type: 'tables', data: trialOutcomesTables },
          { key: 'relapse_times_figures', type: 'figures', data: relapseTimesFigures },
          { key: 'relapse_dynamics_figures', type: 'figures', data: relapseDynamicsFigures },
        ];
      } else if (this.isWorkflowPrediction) {
        // refresh token for calibration images
        await this.fetchCalibrationMediaInfo(...this.input.dataset.split('-'));
        const lesionLoad = this.getSummaryItemLabel(
          this.calibration.input.lesion_load,
          [
            { value: 'high', label: 'High' },
            { value: 'medium-low', label: 'Medium / low' },
            { value: 'unknown', label: 'Unknown' },
          ],
        );
        const oligoclonalBands = this.getSummaryItemLabel(
          this.calibration.input.oligoclonal_bands,
          [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
            { value: 'unknown', label: 'Unknown' },
          ],
        );
        // prepare tables
        let clinicalRelapsesTable = {};
        if (this.calibration.input.relapses != null && this.calibration.input.relapses.length > 0) {
          clinicalRelapsesTable = {
            header: ['', 'Date of clinical relapse (YYYY-MM-DD)', 'Increase of lesion number, from MRI'],
            caption: 'Clinical relapses',
            rows: this.calibration.input.relapses.map((x, index) => [index, x.relapse_date, x.relapse_lesion]),
          };
        }
        let treatmentHistoryTable = {};
        if (this.calibration.input.treatment_history != null && this.calibration.input.treatment_history.length > 0) {
          treatmentHistoryTable = {
            header: ['', 'Treatment type', 'First dosing (YYYY-MM-DD)', 'Final dosing (YYYY-MM-DD)'],
            caption: 'Treatment history',
            rows: this.calibration.input.treatment_history.map((x, index) => [index, x.treatment_type, x.treatment_first, x.treatment_final]),
          };
        }
        // prepare plots
        const virtualPatientFigure = {
          url: this.calibrationResultsURL(`${this.output.user_input.figure_name}`),
          caption: 'Lesion activity and clinical history for selected virtual patient',
          limitHeight: true,
        };
        const dynamicsFigures = [];
        this.output.figures.forEach((x) => {
          dynamicsFigures.push({
            title: `### Prediction results for ${x.scenario_type}`,
            url: this.resultsURL(`${x.relapses}`),
            caption: 'Simulated lesion activity history and simulated future lesion activity for selected virtual patient',
            limitHeight: true,
          });
          dynamicsFigures.push({
            url: this.resultsURL(`${x.ck}`),
            caption: 'Simulated cytokine dynamics history and simulation of future cytokine dynamics for selected virtual patient',
            limitHeight: true,
          });
          dynamicsFigures.push({
            url: this.resultsURL(`${x.lk}`),
            caption: 'Simulated leukocyte dynamics history and simulation of future leukocyte dynamics for selected virtual patient',
            limitHeight: true,
          });
          dynamicsFigures.push({
            url: this.resultsURL(`${x.ig}`),
            caption: 'Simulated antibody dynamics history and simulation of future antibody dynamics for selected virtual patient',
            limitHeight: true,
          });
        });
        // report
        reportTokens = [
          ...userContentTokens,
          { key: 'title', type: 'string', data: this.job.name },
          { key: 'subtitle', type: 'string', data: `${this.$product.name} - Digital Twin Report` },
          { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
          {
            key: 'treatment_legend_table',
            type: 'table',
            data: {
              header: ['Abbreviation', 'Treatment'],
              caption: 'Treatment abbreviation legend',
              rows: [
                ['placebo', 'Placebo'],
                ['ifnb1a-22', 'IFN-$\\beta 1 \\mathrm{a}$ - 22 mcg, 3 times a week'], // IFN-β1a ...
                ['ifnb1a-44', 'IFN-$\\beta 1 \\mathrm{a}$ - 44 mcg, 3 times a week'], // IFN-β1a ...
                ['terif', 'Teriflunomide - 14 mg, QD'],
                ['natal-4wks', 'Natalizumab - 300 mg, every 4 weeks'],
                ['natal-6wks', 'Natalizumab - 300 mg, every 6 weeks'],
                ['ocrel', 'Ocrelizumab - 600 mg, every 6 months'],
              ],
            },
          },
          {
            key: 'basal_patient_characteristics_stack',
            type: 'stack',
            data: [
              { type: 'string', data: `Date of birth (YYYY-MM-DD): ${this.calibration.input.date_of_birth}` },
              { type: 'string', data: `Date of onset (YYYY-MM-DD): ${this.calibration.input.date_of_onset}` },
              { type: 'string', data: `Date of reference (YYYY-MM-DD): ${this.calibration.input.date_reference}` },
              { type: 'string', data: `Lesion load: ${lesionLoad}` },
              { type: 'string', data: `Oligoclonal bands: ${oligoclonalBands}` },
            ],
          },
          this.calibration.input.relapses != null && this.calibration.input.relapses.length > 0 ? { key: 'clinical_relapses_table', type: 'table', data: clinicalRelapsesTable } : {},
          this.calibration.input.treatment_history != null && this.calibration.input.treatment_history.length > 0 ? { key: 'treatment_history_table', type: 'table', data: treatmentHistoryTable } : {},
          {
            key: 'calibration_and_prediction_settings_stack',
            type: 'stack',
            data: [
              { type: 'string', data: `Number of virtual patients in calibration: ${this.calibration.input.repeats}` },
              { type: 'string', data: `Virtual patient chosen for prediction: ${this.input.vp_num}` },
              { type: 'string', data: `Length of prediction simulation (years): ${this.input.length_of_simulation}` },
              { type: 'string', data: `Chosen treatment options for prediction: ${this.input.scenarios.join(', ')}` },
            ],
          },
          { key: 'calibration_results', type: 'string', data: `Lesion activity during the calibration simulation, for virtual patient ${this.input.vp_num}:` },
          { key: 'virtual_patient_figure', type: 'figure', data: virtualPatientFigure },
          { key: 'dynamics_figures', type: 'figures', data: dynamicsFigures },
        ];
      }
      // request PDF
      const response = await this.$api.call('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: reportTemplate,
          tokens: reportTokens,
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'MS_TreatSim_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
    capitalise(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.tabs-with-space {
  margin-top: 3em;
  margin-bottom: 2em;
}
.figure-with-space {
  max-width: 80%;
  margin: auto;
  margin-top: 1.5em;
  margin-bottom: 3em;
}
.table-second-header {
  background-color: #ccf0ff;
}
.title-with-margin {
  margin-top: 2.5em;
}
.title-with-small-margin {
  margin-top: 1.5em;
}
</style>
