<template>
  <div class="section">
    <div class="container ist-container">
      <h1 class="title">
        New Simulation
      </h1>
      <StepIndicator
        v-model="activeStep"
        :labels="stepLabels"
        :warnings="stepWarnings"
      />
      <!-- step 0 -->
      <div v-if="activeStep === 0">
        <h2 class="title is-4">
          Setup
        </h2>
        <div class="columns">
          <div class="column is-6">
            <template
              v-for="element in simulation.setup"
              :key="element.id"
            >
              <FieldFactory
                v-if="element.id === 'workflow_digital_twin' ? isWorkflowCalibration || isWorkflowPrediction || isWorkflowPredictionDisabled : true"
                :config="element"
                :validate-flag="validateFlag"
              />
            </template>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>

      <!-- In silico trial -->
      <template v-if="isWorkflowTrial">
        <!-- step 1 -->
        <div v-if="activeStep === 1">
          <h2 class="title is-4">
            Population definition
          </h2>
          <div class="columns">
            <div class="column is-6">
              <h2 class="title is-4">
                Base characteristics
                <FieldHelp
                  :text="tooltip.trial.base_characteristics"
                />
              </h2>
              <template
                v-for="element in simulation.trial.inclusion[0]"
                :key="element.id"
              >
                <FieldFactory
                  v-if="element.id.startsWith('age_of_onset_ratio') ? isAgeOfOnsetCustom : true"
                  :config="element"
                  :validate-flag="validateFlag"
                />
              </template>
            </div>
            <div class="column is-6">
              <h2 class="title is-4">
                Disease history
                <FieldHelp
                  :text="tooltip.trial.disease_history"
                />
              </h2>
              <FieldFactory
                v-for="element in simulation.trial.inclusion[1]"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
          </div>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 2 -->
        <div v-if="activeStep === 2">
          <h2 class="title is-4">
            Trial design
            <FieldHelp
              :text="tooltip.trial.trial_design"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <button
                class="button is-primary"
                :disabled="simulation.trial.trial_design.length >= 3"
                @click="addBlock(simulation.trial.trial_design, trial_design)"
              >
                Add unique group
              </button>
            </div>
          </div>
          <template
            v-for="(part, index) in simulation.trial.trial_design"
            :key="index"
          >
            <div class="columns">
              <div class="column is-3">
                <FieldFactory
                  :config="part[0]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-3">
                <FieldFactory
                  :config="part[1]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-5">
                <FieldFactory
                  :config="part[2]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-1">
                <div class="field">
                  <label class="label">&nbsp;</label>
                  <div class="control">
                    <button
                      v-if="index != 0"
                      class="button is-danger"
                      @click="removeBlock(simulation.trial.trial_design, index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <p class="with-space">
            <em>
              If you wish to know more about the possibilities to incorporate a novel mechanism of action in the simulator, please fill in a
              <a
                :href="wrikeLink"
                rel="noopener noreferrer"
                target="_blank"
              >support request</a>.
            </em>
          </p>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 3 -->
        <div v-if="activeStep === 3">
          <h2 class="title is-4">
            Trial duration
            <FieldHelp
              :text="tooltip.trial.timeline"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldFactory
                v-for="element in simulation.trial.timeline[0]"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
          </div>
          <h2 class="title is-4">
            Interim time points
            <FieldHelp
              :text="tooltip.trial.interims"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <button
                class="button is-primary"
                :disabled="simulation.trial.timeline[1].length >= 5"
                @click="addBlock(simulation.trial.timeline[1], interim)"
              >
                Add interim
              </button>
            </div>
          </div>
          <template
            v-for="(part, index) in simulation.trial.timeline[1]"
            :key="index"
          >
            <div class="columns">
              <div class="column is-6">
                <FieldFactory
                  :config="part[0]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-1">
                <div class="field">
                  <label class="label">&nbsp;</label>
                  <div class="control">
                    <button
                      class="button is-danger"
                      @click="removeBlock(simulation.trial.timeline[1], index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 4 -->
        <div v-if="activeStep === 4">
          <h2 class="title is-4">
            Summary
          </h2>
          <template
            v-for="element in simulation.setup"
            :key="element.id"
          >
            <SummaryItem
              v-if="element.id === 'workflow_digital_twin' ? isWorkflowCalibration || isWorkflowPrediction || isWorkflowPredictionDisabled : true"
              :config="element"
            />
          </template>
          <template
            v-for="element in simulationFlat"
            :key="element.id"
          >
            <SummaryItem
              v-if="element.id.startsWith('age_of_onset_ratio') ? isAgeOfOnsetCustom : true"
              :config="element"
            />
          </template>
          <p
            style="margin-top: 2em"
            data-cy="tokens"
          >
            The simulation will consume {{ tokenCounter }}
            <span>token</span>
            <span v-if="tokenCounter > 1">s</span>.
            <span
              class="tag is-medium space-left"
              :class="{ 'is-warning': tokenCounter > tokensAvailable }"
            >{{ tokensAvailable }} tokens available</span>
          </p>
          <p>
            Each unique group included the trial simulation will consume 2 tokens.
          </p>
          <p v-if="tokenCounter > tokensAvailable">
            Not enough available tokens
          </p>
          <p v-if="!jobValidates">
            Provide missing input.
          </p>
          <button
            id="submit"
            class="button run-button"
            :class="{
              'is-warning': !allowedToSubmit,
              'is-success': allowedToSubmit,
            }"
            :disabled="isSubmitting || !allowedToSubmit"
            @click="submitJob"
          >
            <Loader :is-loading="isSubmitting" />Run Simulation
          </button>
          <StepButtons
            v-model="activeStep"
            is-last
          />
        </div>
      </template>

      <!-- Digital twin - calibration -->
      <template v-if="isWorkflowCalibration">
        <!-- step 1 -->
        <div v-if="activeStep === 1">
          <h2 class="title is-4">
            Patient
            <FieldHelp
              :text="tooltip.calibration.patient"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldFactory
                v-for="element in simulation.calibration.characteristics[0]"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
            <div class="column is-6">
              <FieldFactory
                v-for="element in simulation.calibration.characteristics[1]"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
          </div>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 2 -->
        <div v-if="activeStep === 2">
          <h2 class="title is-4">
            Relapse History
          </h2>
          <div class="columns">
            <div class="column is-6">
              <button
                class="button is-primary"
                :disabled="simulation.calibration.history[0].length >= 30"
                @click="addBlock(simulation.calibration.history[0], relapse)"
              >
                Add relapse
              </button>
            </div>
          </div>
          <template
            v-for="(part, index) in simulation.calibration.history[0]"
            :key="index"
          >
            <div class="columns">
              <div class="column is-5">
                <FieldFactory
                  :config="part[0]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-3">
                <FieldFactory
                  :config="part[1]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-1">
                <div class="field">
                  <label class="label">&nbsp;</label>
                  <div class="control">
                    <button
                      v-if="index != 0"
                      class="button is-danger"
                      @click="removeBlock(simulation.calibration.history[0], index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <h2 class="title is-4">
            Treatment History
            <FieldHelp
              :text="tooltip.calibration.treatment_history"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <button
                class="button is-primary"
                :disabled="simulation.calibration.history[1].length >= 20"
                @click="addBlock(simulation.calibration.history[1], treatment)"
              >
                Add treatment
              </button>
            </div>
          </div>
          <template
            v-for="(part, index) in simulation.calibration.history[1]"
            :key="index"
          >
            <div class="columns">
              <div class="column is-5">
                <FieldFactory
                  :config="part[0]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-3">
                <FieldFactory
                  :config="part[1]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-3">
                <FieldFactory
                  :config="part[2]"
                  :validate-flag="validateFlag"
                />
              </div>
              <div class="column is-1">
                <div class="field">
                  <label class="label">&nbsp;</label>
                  <div class="control">
                    <button
                      class="button is-danger"
                      @click="removeBlock(simulation.calibration.history[1], index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 3 -->
        <div v-if="activeStep === 3">
          <h2 class="title is-4">
            Simulation settings
            <FieldHelp
              :text="tooltip.calibration.simulation_settings"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldFactory
                v-for="element in simulation.calibration.settings[0]"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
          </div>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 4 -->
        <div v-if="activeStep === 4">
          <h2 class="title is-4">
            Summary
          </h2>
          <template
            v-for="element in simulation.setup"
            :key="element.id"
          >
            <SummaryItem
              v-if="element.id === 'workflow_digital_twin' ? isWorkflowCalibration || isWorkflowPrediction || isWorkflowPredictionDisabled : true"
              :config="element"
            />
          </template>
          <SummaryItem
            v-for="element in simulationFlat"
            :key="element.id"
            :config="element"
          />
          <p
            style="margin-top: 2em"
            data-cy="tokens"
          >
            The simulation will consume {{ tokenCounter }}
            <span>token</span>
            <span v-if="tokenCounter > 1">s</span>.
            <span
              class="tag is-medium space-left"
              :class="{ 'is-warning': tokenCounter > tokensAvailable }"
            >{{ tokensAvailable }} tokens available</span>
          </p>
          <p v-if="tokenCounter > tokensAvailable">
            Not enough available tokens
          </p>
          <p v-if="!jobValidates">
            Provide missing input.
          </p>
          <button
            id="submit"
            class="button run-button"
            :class="{
              'is-warning': !allowedToSubmit,
              'is-success': allowedToSubmit,
            }"
            :disabled="isSubmitting || !allowedToSubmit"
            @click="submitJob"
          >
            <Loader :is-loading="isSubmitting" />Run Simulation
          </button>
          <StepButtons
            v-model="activeStep"
            is-last
          />
        </div>
      </template>

      <!-- Digital twin - prediction DISABLED -->
      <template v-if="isWorkflowPredictionDisabled">
        <!-- step 1 -->
        <div v-if="activeStep === 1">
          <h2 class="title is-4">
            Calibration required
          </h2>
          <p class="with-space">
            It's requested to perform a <strong>digital twin - calibration</strong> before using this workflow
          </p>
          <br>
          <StepButtons
            v-model="activeStep"
            is-last
          />
        </div>
      </template>

      <!-- Digital twin - prediction -->
      <template v-if="isWorkflowPrediction">
        <!-- step 1 -->
        <div v-if="activeStep === 1">
          <h2 class="title is-4">
            Select model
            <FieldHelp
              :text="tooltip.prediction.select_model"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldSelect
                :id="simulation.prediction.select_model[0].id"
                v-model="calibrationDatasetModel"
                :options="simulation.prediction.select_model[0].options"
                :label="simulation.prediction.select_model[0].label"
              />
              <FieldSelect
                :id="simulation.prediction.select_model[1].id"
                v-model="virtualPatientModel"
                :options="simulation.prediction.select_model[1].options"
                :label="simulation.prediction.select_model[1].label"
              />
            </div>
            <div class="column is-6">
              <figure
                v-if="virtualPatientFigure"
                class="image"
              >
                <img :src="virtualPatientFigure">
              </figure>
            </div>
          </div>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 2 -->
        <div v-if="activeStep === 2">
          <h2 class="title is-4">
            Simulation settings
            <FieldHelp
              :text="tooltip.prediction.simulation_settings"
            />
          </h2>
          <div class="columns">
            <div class="column is-6">
              <FieldFactory
                v-for="element in simulation.prediction.scenarios"
                :key="element.id"
                :config="element"
                :validate-flag="validateFlag"
              />
            </div>
          </div>
          <StepButtons v-model="activeStep" />
        </div>
        <!-- step 3 -->
        <div v-if="activeStep === 3">
          <h2 class="title is-4">
            Summary
          </h2>
          <template
            v-for="element in simulation.setup"
            :key="element.id"
          >
            <SummaryItem
              v-if="element.id === 'workflow_digital_twin' ? isWorkflowCalibration || isWorkflowPrediction || isWorkflowPredictionDisabled : true"
              :config="element"
            />
          </template>
          <SummaryItem
            v-for="element in simulationFlat"
            :key="element.id"
            :config="element"
          />
          <p
            style="margin-top: 2em"
            data-cy="tokens"
          >
            The simulation will consume {{ tokenCounter }}
            <span>token</span>
            <span v-if="tokenCounter > 1">s</span>.
            <span
              class="tag is-medium space-left"
              :class="{ 'is-warning': tokenCounter > tokensAvailable }"
            >{{ tokensAvailable }} tokens available</span>
          </p>
          <p v-if="tokenCounter > tokensAvailable">
            Not enough available tokens
          </p>
          <p v-if="!jobValidates">
            Provide missing input.
          </p>
          <button
            id="submit"
            class="button run-button"
            :class="{
              'is-warning': !allowedToSubmit,
              'is-success': allowedToSubmit,
            }"
            :disabled="isSubmitting || !allowedToSubmit"
            @click="submitJob"
          >
            <Loader :is-loading="isSubmitting" />Run Simulation
          </button>
          <StepButtons
            v-model="activeStep"
            is-last
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import FieldFactory from 'ist-skeleton-vue/src/components/Simulation/FieldFactory.vue';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import FieldSelect from 'ist-skeleton-vue/src/components/Simulation/FieldSelect.vue';
import StepButtons from 'ist-skeleton-vue/src/components/Simulation/StepButtons.vue';
import StepIndicator from 'ist-skeleton-vue/src/components/Simulation/StepIndicator.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';

export default {
  components: {
    Loader,
    FieldFactory,
    FieldHelp,
    FieldSelect,
    StepButtons,
    StepIndicator,
    SummaryItem,
  },
  data() {
    return {
      activeStep: 0,
      validateFlag: false, // global validation flag (to fire field validation)
      highestVisitedStep: 0, // up to which step to run validation
      wrikeLink: this.$support,
      // tokens
      tokensAvailable: 0,
      tokenCounter: 1,
      // form status
      isSubmitting: false,
      jobValidates: false,
      stepWarnings: [false, false, false, false, false],
      // user data below
      wizard: {
        trial: ['Setup', 'Population', 'Trial design', 'Timeline', 'Run'],
        calibration: ['Setup', 'Patient', 'History', 'Settings', 'Run'],
        prediction: ['Setup', 'Select model', 'Scenarios', 'Run'],
        prediction_disabled: ['Setup', 'Calibration required'],
      },
      // tooltip (help)
      tooltip: {
        trial: {
          base_characteristics: 'The base characteristics will be used to set model parameters to represent the individual patients.',
          lesion_load: 'Choose between 100% of patients with either high or low/medium lesion load, or a random sampling (50% chance of high lesion load, 50% chance of low/medium lesion load).',
          disease_history: 'Virtual patients will be created by sampling base characteristics, converting these to model parameters, simulating a patient for the disease duration, and then including or disregarding a simulation based on the activity criteria.',
          trial_design: 'Treatment and control groups can be added to the trial design. More than one treatment can be tested on the same group (i.e., the same virtual patients). It is recommended to use distinct groups to account for the variability between groups in an actual clinical trial.',
          timeline: 'Relapse statistics and mean immune system measures will be determined at both the interim time points (optional) and at the end of the trial.',
          interims: 'Output statistics are determined not only at the trial endpoint, but also at selected interim time points. Interim time points do not affect computation time.',
        },
        calibration: {
          patient: 'Patient’s basal characteristics to set model parameters.',
          treatment_history: 'Treatment options: \n 1. If “last dose” is left blank, only a single dose treatment is simulated. \n 2. If both first and last dosing dates are earlier than the reference date, treatment will be simulated for the period between from the first up to and including the last dosing date at the selected dose and interval. \n 3. For currently ongoing treatment, the reference date (as defined in the previous step, usually today) should be chosen as last dosing date.',
          simulation_settings: 'Input requires number of virtual patients to be generated. Virtual patients are generated at random and a good match to true patient history must be manually selected. More virtual patients will allow a wider selection of options; however, the computation will also take longer to complete.',
        },
        prediction: {
          select_model: 'To make a prediction of a patient’s response to therapy, a virtual patient that closely reproduces the true patient’s clinical history must be selected from the calibration set. For this purpose, the location of true relapses with confirmed increase of lesion load (blue bands) and the location of simulated relapses (dots, representing lesion activity) are plotted together in the figure shown below. The relative location of relapses should be considered, and relapse severity should be weighted if necessary (by height of relapse activity, see also the relapse thresholds shown on the right). The virtual patients in a set are ranked according to an automated measure of distance between simulated and true relapses, but final selection of the best virtual patient is performed by the user.',
          simulation_settings: 'The simulation period determines the duration of the predictive part of the simulation.',
        },
      },
      simulation: {
        // Setup
        setup: [
          {
            id: 'title',
            label: 'Title',
            model: { value: '', ok: false },
          },
          {
            id: 'workflow',
            label: 'Select simulation type',
            help: 'Please choose between the In Silico Trial workflow and the Digital Twin workflow.\n - The In Silico Trial workflow allows simultaneous simulation of groups of patients undergoing treatments, mirroring a clinical trial.\n - The Digital Twin workflow creates the digital twin of a specific patient, and generates predictions of treatment responses.',
            type: 'radio',
            model: { value: 'trial', ok: true },
            options: [
              { value: 'trial', label: 'In Silico Trial' },
              { value: 'digital_twin', label: 'Digital Twin' },
            ],
          },
          {
            id: 'workflow_digital_twin',
            label: 'Digital twin',
            help: 'The Digital Twin workflow consists of the calibration and prediction steps. The prediction step can only be performed after the calibration step is complete.\n\n1. Calibration step: simulates a series of virtual patients with personalized base characteristics and treatment history, in order to calibrate the personal relapse history of interest. User must select the best match within the series before continuing.\n\n2. Prediction step: select treatment scenarios for the best virtual patient match.',
            type: 'select',
            model: { value: 'calibration', ok: true },
            options: [
              { value: 'calibration', label: 'Calibration' },
              { value: 'prediction', label: 'Prediction' },
            ],
          },
        ],
        // In silico trial
        trial: {
          inclusion: [
            [
              {
                id: 'lesion_load',
                label: 'Lesion load',
                help: 'Lesion load options: \n - 100% patients with high lesion load \n - 100% patients with low/medium lesion load \n - 50 % chance high lesion load, 50 % chance low/medium lesion load by random sampling',
                type: 'select',
                model: { value: 'default', ok: true },
                options: [
                  { value: 'high', label: 'High lesion load' },
                  { value: 'low', label: 'Medium / low lesion load' },
                  { value: 'default', label: '50 % chance of high lesion load' },
                ],
              },
              {
                id: 'oligoclonal_bands',
                label: 'Oligoclonal bands',
                help: 'Oligoclonal bands options: \n - 100% patients with oligoclonal bands present \n - 100% patients with oligoclonal bands not present \n - 90 % chance oligoclonal bands present by random sampling ',
                type: 'select',
                model: { value: 'default', ok: true },
                options: [
                  { value: 'yes', label: 'Oligoclonal bands present' },
                  { value: 'no', label: 'Oligoclonal bands not present' },
                  { value: 'default', label: '90 % chance of oligoclonal bands presence' },
                ],
              },
              {
                id: 'age_of_onset_type',
                label: 'Age of clinical onset (years)',
                help: 'Age of clinical onset options: \n - Default age distribution: age categories are randomly sampled with following distribution: 49.4% from 18-29; 36.2% from 30-39; and 14.4% from 40-49 years. This distribution was determined from a lognormal distribution with a median of 32.5 years, bounded at [18-49] years. \n - Custom age distribution: percentage or ratio of patients from each category can be manually defined. The sum of the three values must be different from zero. Custom age distribution examples: \n    * [60, 40, 0] : 60 % chance of age from 18-29 years, 40% chance of age from 30-39 years \n    * [1,1,1] : equal chance for each category (33.3%, 33.3% and 33.3%).',
                type: 'radio',
                model: { value: 'default', ok: true },
                options: [
                  { value: 'default', label: 'Use default age distribution' },
                  { value: 'custom', label: 'Define custom age distribution' },
                ],
              },
              {
                id: 'age_of_onset_ratio_first',
                label: 'Ages 18-29 (ratio)',
                placeholder: '1',
                model: { value: '1', ok: true },
                vmin: 0,
                vmax: 100,
              },
              {
                id: 'age_of_onset_ratio_second',
                label: 'Ages 30-39 (ratio)',
                placeholder: '1',
                model: { value: '1', ok: true },
                vmin: 0,
                vmax: 100,
              },
              {
                id: 'age_of_onset_ratio_third',
                label: 'Ages 40-49 (ratio)',
                placeholder: '1',
                model: { value: '1', ok: true },
                vmin: 0,
                vmax: 100,
              },
            ],
            [
              {
                id: 'duration_min',
                label: 'Disease duration - minimum (years)',
                placeholder: '1',
                model: { value: '1', ok: true },
                vinteger: true,
                vmin: 1,
                // set in mounted phase
                vmax_default: 5,
                vmax: null,
              },
              {
                id: 'duration_max',
                label: 'Disease duration - maximum (years)',
                placeholder: '5',
                model: { value: '1', ok: true },
                vinteger: true,
                vmax: 5,
                // set in mounted phase
                vmin_default: 1,
                vmin: null,
              },
              {
                id: 'activity_criteria',
                label: 'Disease activity',
                help: 'To guarantee disease activity, please select one or more disease activity requirement options starting with “At least...”.',
                type: 'checkbox',
                model: {
                  value: {
                    month: true,
                    halfyear: false,
                    year: true,
                    twoyears: false,
                    yearORtwoyears: false,
                  },
                },
                options: [
                  { value: 'month', label: 'No relapse in 30 days prior to trial start' },
                  { value: 'halfyear', label: 'At least 1 relapse in 6 months prior to trial start' },
                  { value: 'year', label: 'At least 1 relapse in year prior to trial start' },
                  { value: 'twoyears', label: 'At least 2 relapses in 2 years prior to trial start' },
                  { value: 'yearORtwoyears', label: 'At least 1 relapse in year, or 2 relapses in two years prior to trial start' },
                ],
              },
            ],
          ],
          trial_design: [
            // trial_design's space
          ],
          timeline: [
            [
              {
                id: 'trial_length',
                label: 'Total trial duration (weeks)',
                placeholder: '96',
                model: { value: '96', ok: true },
                vinteger: true,
                vmin: 12,
                vmax: 260,
              },
            ],
            [
              // interim's space
            ],
          ],
        },
        // Digital twin - calibration
        calibration: {
          characteristics: [
            [
              {
                id: 'date_of_birth',
                label: 'Date of birth',
                help: 'The patient’s age at clinical onset must be between 18 and 49 years.',
                type: 'date',
                model: { value: '', ok: false },
                // set in mounted phase
                vmin: null,
                vmax: null,
              },
              {
                id: 'date_of_onset',
                label: 'Date of clinical onset',
                help: 'The date of diagnosis by MRI or first relapse confirmed by MRI. The difference between the date of clinical onset and the date of birth must be in the 18-49 years range.',
                type: 'date',
                model: { value: '', ok: false },
                // set in mounted phase
                vmin: null,
                vmax: null,
              },
              {
                id: 'date_reference',
                label: 'Reference date',
                help: 'The reference date is the date from which predictions are simulated. In most cases, the reference date is today (i.e., the date the user launches the simulation). In case a patient would need to be simulated starting from a date in the past, the date in the past should be chosen (e.g., if a patient history would be  available starting from 1st of Januari 2015 and predictions should cover 2015 and 2016, the reference date should be 1st of January 2015). The reference date must be equal to or later than  the date of clinical onset.',
                type: 'date',
                model: { value: '', ok: false },
                // set in mounted phase
                vmin: null,
                vmax: null,
              },
            ],
            [
              {
                id: 'oligoclonal_bands',
                label: 'Oligoclonal bands',
                help: 'When the oligoclonal band option of “unknown” is selected, a 90 % chance of oligoclonal band presence is used.',
                type: 'select',
                model: { value: 'unknown', ok: true },
                options: [
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                  { value: 'unknown', label: 'Unknown' },
                ],
              },
              {
                id: 'lesion_load',
                label: 'Lesion load',
                type: 'select',
                help: 'When the lesion load option “unknown” is selected, a 50 % chance of high lesion load, and 50 % chance of low/medium lesion load is used.',
                model: { value: 'unknown', ok: true },
                options: [
                  { value: 'high', label: 'High' },
                  { value: 'medium-low', label: 'Medium / low' },
                  { value: 'unknown', label: 'Unknown' },
                ],
              },
            ],
          ],
          history: [
            [
              // relapses' space
            ],
            [
              // treatments' space
            ],
          ],
          settings: [
            [
              {
                id: 'repeats',
                label: 'Number of virtual patients',
                placeholder: '10',
                model: {
                  value: '10', ok: true,
                },
                vinteger: true,
                vmin: 10,
                vmax: 25,
              },
            ],
          ],
        },
        // Digital twin - prediction
        prediction: {
          select_model: [
            {
              id: 'dataset',
              label: 'Calibration dataset',
              type: 'select',
              // calibrationDatasetModel - watch model
              model: null,
              options: [],
            },
            // virtual patient's space
          ],
          scenarios:
          [
            {
              id: 'length_of_simulation',
              label: 'Simulation period (years)',
              help: 'The length of simulation determines how far into the future the virtual patient will be simulated.',
              placeholder: '2',
              model: {
                value: '2', ok: true,
              },
              vinteger: true,
              vmin: 0,
              vmax: 20,
            },
            {
              id: 'scenarios',
              label: 'Treatment options',
              help: 'A prediction simulation will be performed for each selected treatment option. In all cases, the chosen treatment will be applied for the entire duration of the predictive part of the simulation.',
              type: 'checkbox',
              model: {
                value: {
                  naive: true,
                  'ifnb1a-22': false,
                  'ifnb1a-44': false,
                  terif: false,
                  'natal-4wks': false,
                  'natal-6wks': false,
                  ocrel: false,
                },
              },
              options: [
                { value: 'naive', label: 'No treatment' },
                { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
                { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
                { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
                { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
                { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
                { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
              ],
            },
          ],
        },
        // Digital twin - prediction DISABLED
        prediction_disabled: {},
      },
      relapse: [
        {
          id: 'relapse_date',
          label: 'Date of clinical relapse',
          help: 'Relapse history requires a patient’s relapse date and  the increase of lesion number as confirmed by MRI. The date of diagnosis/onset must be included as the date of the first clinical relapse. The lesion number increase quantity is stratified as follows: \n - No increase of lesion number: relapse has not been confirmed and will not be taken into account. \n - 1-10: low lesional load increase. \n - 10-20: medium lesional load increase. \n - >20: high lesional load increase.',
          type: 'date',
          model: { value: '', ok: false },
          // set in mounted phase
          vmin: null,
          vmax: null,
        },
        {
          id: 'relapse_lesion',
          label: 'Increase of lesion number (MRI)',
          model: { value: '', ok: true },
          vinteger: true,
          vmin: 0,
          optional: true,
        },
      ],
      treatment: [
        {
          id: 'treatment_type',
          label: 'Treatment type',
          type: 'select',
          model: { value: 'ifnb1a-22', ok: true },
          options: [
            { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
            { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
            { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
            { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
            { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
            { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
          ],
        },
        {
          id: 'treatment_first',
          label: 'First dosing',
          type: 'date',
          model: { value: '', ok: false },
          // set in mounted phase
          vmin: null,
          vmax: null,
        },
        {
          id: 'treatment_final',
          label: 'Final dosing',
          type: 'date',
          model: { value: '', ok: true },
          optional: true,
          // set in mounted phase
          vmin: null,
          vmax: null,
        },
      ],
      trial_design: [
        {
          id: 'trial_design_group_name',
          label: 'Virtual group name',
          placeholder: 'Treatment group',
          model: { value: 'Treatment group', ok: true },
        },
        {
          id: 'trial_design_group_size',
          label: 'Size (virtual patients)',
          placeholder: '20',
          model: { value: '20', ok: true },
          vinteger: true,
          vmin: 10,
          vmax: 50,
        },
        {
          id: 'trial_design_treatments',
          label: 'Treatment(s)',
          type: 'checkbox',
          model: {
            value: {
              naive: true,
              'ifnb1a-22': false,
              'ifnb1a-44': false,
              terif: false,
              'natal-4wks': false,
              'natal-6wks': false,
              ocrel: false,
            },
          },
          options: [
            { value: 'naive', label: 'Placebo' },
            { value: 'ifnb1a-22', label: 'IFN-β1a - 22 mcg, 3 times a week' },
            { value: 'ifnb1a-44', label: 'IFN-β1a - 44 mcg, 3 times a week' },
            { value: 'terif', label: 'Teriflunomide - 14 mg, QD' },
            { value: 'natal-4wks', label: 'Natalizumab - 300 mg, every 4 weeks' },
            { value: 'natal-6wks', label: 'Natalizumab - 300 mg, every 6 weeks' },
            { value: 'ocrel', label: 'Ocrelizumab - 600 mg, every 6 months' },
          ],
        },
      ],
      interim: [
        {
          id: 'interim',
          label: 'Perform interim analysis at (weeks)',
          placeholder: '48',
          model: { value: '48', ok: true },
          vinteger: true,
          vmin: 1,
          // set in mounted phase
          vmax: null,
        },
      ],
      calibrations: null,
      calibrationDatasetModel: null,
      virtualPatient: {
        id: 'vp_num',
        label: 'Virtual patient',
        type: 'select',
        // virtualPatientModel - watch model
        model: null,
        options: [],
      },
      virtualPatientModel: null,
      virtualPatients: null,
      virtualPatientFigure: null,
      todayToString: null,
    };
  },
  computed: {
    title() {
      return this.simulation.setup[0];
    },
    workflow() {
      if (this.simulation.setup[1].model.value === 'trial') {
        return 'trial';
      }
      if (this.simulation.setup[2].model.value === 'calibration') {
        return 'calibration';
      }
      if (this.calibrations != null && this.calibrations.length > 0) {
        return 'prediction';
      }
      return 'prediction_disabled';
    },
    isWorkflowTrial() {
      return this.workflow === 'trial';
    },
    isWorkflowCalibration() {
      return this.workflow === 'calibration';
    },
    isWorkflowPrediction() {
      return this.workflow === 'prediction';
    },
    isWorkflowPredictionDisabled() {
      return this.workflow === 'prediction_disabled';
    },
    isAgeOfOnsetCustom() {
      return this.simulation.trial.inclusion[0].find((element) => element.id === 'age_of_onset_type').model.value === 'custom';
    },
    stepLabels() {
      return this.wizard[this.workflow];
    },
    simulationFlat() {
      return Object.values(this.simulation[this.workflow]).flat(Infinity);
    },
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    dateOfBirthMin() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_onset').model;
        if (value) {
          const date = new Date(`${value}T00:00:00`);
          date.setFullYear(date.getFullYear() - 50);
          date.setDate(date.getDate() + 1);
          const dateToString = this.dateToString(date);
          return dateToString < this.todayToString ? dateToString : this.todayToString;
        }
        return '';
      };
    },
    dateOfBirthMax() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_onset').model;
        if (value) {
          const date = new Date(`${value}T00:00:00`);
          date.setFullYear(date.getFullYear() - 18);
          const dateToString = this.dateToString(date);
          return dateToString < this.todayToString ? dateToString : this.todayToString;
        }
        return '';
      };
    },
    dateOfOnsetMin() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_birth').model;
        if (value) {
          const date = new Date(`${value}T00:00:00`);
          date.setFullYear(date.getFullYear() + 18);
          const dateToString = this.dateToString(date);
          return dateToString < this.todayToString ? dateToString : this.todayToString;
        }
        return '';
      };
    },
    dateOfOnsetMax() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_birth').model;
        if (value) {
          const date = new Date(`${value}T00:00:00`);
          date.setFullYear(date.getFullYear() + 50);
          date.setDate(date.getDate() - 1);
          const dateToString = this.dateToString(date);
          return dateToString < this.todayToString ? dateToString : this.todayToString;
        }
        return '';
      };
    },
    dateReferenceMin() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_onset').model;
        if (value) {
          return value < this.todayToString ? value : this.todayToString;
        }
        return '';
      };
    },
    dateReferenceMax() {
      // return function to pass computed method by reference
      return () => this.todayToString;
    },
    dateHistoryMin() {
      // return function to pass computed method by reference
      return () => this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_onset').model.value || '';
    },
    dateHistoryMax() {
      // return function to pass computed method by reference
      return () => this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_reference').model.value || '';
    },
    interimMax() {
      // return function to pass computed method by reference
      return () => {
        const { value } = this.simulation.trial.timeline[0].find((element) => element.id === 'trial_length').model;
        if (value && Number(value)) {
          return Number(value);
        }
        return null;
      };
    },
    durationMinMax() {
      // return function to pass computed method by reference
      return () => {
        const vmaxDefault = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_min').vmax_default;
        const { value } = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_max').model;
        if (value && Number(value)) {
          return Number(value) < vmaxDefault ? Number(value) : vmaxDefault;
        }
        return vmaxDefault;
      };
    },
    durationMaxMin() {
      // return function to pass computed method by reference
      return () => {
        const vminDefault = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_max').vmin_default;
        const { value } = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_min').model;
        if (value && Number(value)) {
          return vminDefault < Number(value) ? Number(value) : vminDefault;
        }
        return vminDefault;
      };
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep > this.highestVisitedStep) this.highestVisitedStep = newStep;
      const numberSteps = this.stepLabels.length;
      if (newStep === numberSteps - 1) {
        this.validateFlag = true;
        this.countTokens();
        this.validateJob();
      }
    },
    /* eslint-disable-next-line no-unused-vars */
    async calibrationDatasetModel(newObj, oldObj) {
      // console.debug('watch (calibrationDatasetModel)', newObj, oldObj);
      const calibrationDataset = this.simulation.prediction.select_model[0];
      calibrationDataset.model = this.calibrationDatasetModel;
      await this.setVirtualPatients(newObj.value);
    },
    /* eslint-disable-next-line no-unused-vars */
    async virtualPatientModel(newObj, oldObj) {
      // console.debug('watch (virtualPatientModel)', newObj, oldObj);
      const virtualPatient = this.simulation.prediction.select_model[1];
      virtualPatient.model = this.virtualPatientModel;
      await this.setVirtualPatientFigure(newObj.value);
    },
  },
  async mounted() {
    this.fetchTokens();
    // Workflow digital twin prediction - Fetch calibrations
    this.calibrations = await this.fetchCalibrations();
    this.setCalibrationDataset();
    // All workflows - Set both vmin vmax for some fields
    this.setFieldsVminVmax();
    // Workflow digital twin calibration - Initialize first relapse
    this.addBlock(this.simulation.calibration.history[0], this.relapse);
    // Workflow in silico trial - Initialize first trial_design
    this.addBlock(this.simulation.trial.trial_design, this.trial_design);
  },
  methods: {
    dateToString(date) {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
    setFieldsVminVmax() {
      // today
      this.todayToString = this.dateToString(new Date());
      // date_of_birth
      const dateOfBirth = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_birth');
      dateOfBirth.vmin = this.dateOfBirthMin;
      dateOfBirth.vmax = this.dateOfBirthMax;
      // date_of_onset
      const dateOfOnset = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_of_onset');
      dateOfOnset.vmin = this.dateOfOnsetMin;
      dateOfOnset.vmax = this.dateOfOnsetMax;
      // dateOfOnset.model.value = this.todayToString;
      // dateOfOnset.model.value = '2021-01-01';
      // dateOfOnset.model.ok = true;
      // date_reference
      const dateReference = this.simulation.calibration.characteristics[0].find((element) => element.id === 'date_reference');
      dateReference.vmin = this.dateReferenceMin;
      dateReference.vmax = this.dateReferenceMax;
      dateReference.model.value = this.todayToString;
      dateReference.model.ok = true;
      // relapse_date
      const dateRelapse = this.relapse.find((element) => element.id === 'relapse_date');
      dateRelapse.vmin = this.dateHistoryMin;
      dateRelapse.vmax = this.dateHistoryMax;
      // treatment_first
      const dateTreatmentFirst = this.treatment.find((element) => element.id === 'treatment_first');
      dateTreatmentFirst.vmin = this.dateHistoryMin;
      dateTreatmentFirst.vmax = this.dateHistoryMax;
      // treatment_final
      const dateTreatmentFinal = this.treatment.find((element) => element.id === 'treatment_final');
      dateTreatmentFinal.vmin = this.dateHistoryMin;
      dateTreatmentFinal.vmax = this.dateHistoryMax;
      // interim
      const interim = this.interim.find((element) => element.id === 'interim');
      interim.vmax = this.interimMax;
      // duration_min
      const durationMin = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_min');
      durationMin.vmax = this.durationMinMax;
      // duration_max
      const durationMax = this.simulation.trial.inclusion[1].find((element) => element.id === 'duration_max');
      durationMax.vmin = this.durationMaxMin;
    },
    async fetchCalibrations() {
      const response = await this.$apollo.query({
        query: gql`
            query getJobs($product_name: ProductName!, $limit: Int) {
              jobs: getJobs(product_name: $product_name, limit: $limit) {
                _id
                name
                status
                tasks {
                  _id
                  status
                  created_at
                  started_at
                  finished_at
                  input
                }
              }
            }
          `,
        variables: {
          product_name: this.$product.id,
          limit: 0, // no limit
        },
      });
      const { jobs } = response.data;
      // console.debug('jobs', jobs);
      if (jobs) {
        // TODO move inside api method ?
        const calibrations = jobs.filter((job) => job && job.status === 'completed' && job.tasks && job.tasks[0] && job.tasks[0].status === 'success' && job.tasks[0].input && job.tasks[0].input.workflow === 'calibration');
        // console.debug('calibrations', calibrations);
        return calibrations;
      }
      return null;
    },
    setCalibrationDataset() {
      if (this.calibrations && this.calibrations.length > 0) {
        const calibrationDataset = this.simulation.prediction.select_model[0];
        this.calibrations.forEach((calib, index) => {
          // eg "jobId-taskId" -> "610c32da13b5e68cfcb0b3fe-610c32da13b5e68cfcb0b3fd"
          // eslint-disable-next-line no-underscore-dangle
          const id = `${calib._id}-${calib.tasks[0]._id}`;
          if (index === 0) {
            this.calibrationDatasetModel = { value: id, ok: true };
          }
          calibrationDataset.options.push({ value: id, label: calib.name });
        });
      }
    },
    async fetchMediaInfo(calibrationId) {
      // eg "jobId-taskId" -> "610c32da13b5e68cfcb0b3fe-610c32da13b5e68cfcb0b3fd"
      const [jobId, taskId] = calibrationId.split('-');
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: jobId,
          task_id: taskId,
        },
      });
      return {
        baseUrl: response.data.info.baseURL,
        containerSAS: response.data.info.containerSAS,
      };
    },
    async fetchCalibrationOutput(calibrationId) {
      const fileName = 'output.json';
      const mediaInfo = await this.fetchMediaInfo(calibrationId);
      const url = `${mediaInfo.baseUrl}${fileName}?${mediaInfo.containerSAS}`;
      const response = await fetch(url);
      return response.json();
    },
    async setVirtualPatients(calibrationId) {
      const virtualPatient = { ...this.virtualPatient, options: [] };
      this.virtualPatients = await this.fetchCalibrationOutput(calibrationId);
      // console.debug('virtualPatients', this.virtualPatients);
      if (this.virtualPatients) {
        this.virtualPatients.forEach((vp, index) => {
          const vpId = vp.rs.toString();
          if (index === 0) {
            this.virtualPatientModel = { value: vpId, ok: true };
          }
          virtualPatient.options.push({ value: vpId, label: vpId });
        });
      }
      this.simulation.prediction.select_model[1] = virtualPatient;
    },
    async setVirtualPatientFigure(vpId) {
      this.virtualPatientFigure = null;
      if (this.virtualPatients) {
        const vp = this.virtualPatients.find((element) => element.rs === Number(vpId));
        if (vp) {
          // console.debug(vpId, vp, vp.figure_name);
          const mediaInfo = await this.fetchMediaInfo(this.calibrationDatasetModel.value);
          this.virtualPatientFigure = `${mediaInfo.baseUrl}${vp.figure_name}?${mediaInfo.containerSAS}`;
        }
      }
    },
    addBlock(section, block) {
      const newBlock = [];
      // Random integer from 0 to 999999
      const random = Math.floor(Math.random() * 1000000);
      block.forEach((element) => {
        const newElement = { ...element, id: `${element.id}_${random}` };
        if (element.type && element.type === 'checkbox') {
          newElement.model = {};
          newElement.model.value = { ...element.model.value };
        }
        newBlock.push(newElement);
      });
      section.push(newBlock);
    },
    removeBlock(section, index) {
      section.splice(index, 1);
    },
    parseValue(element) {
      let ret = null;
      if (element.model.value) {
        if (element.type && element.type === 'checkbox') {
          // type checkbox
          const values = [];
          Object.keys(element.model.value).forEach((key) => {
            if (element.model.value[key]) {
              values.push(key);
            }
          });
          ret = values;
        } else if (element.type && element.type === 'date') {
          // type date
          ret = element.model.value;
        } else if (element.vmin || element.vmax || element.vpositive || element.vinteger) {
          // type number
          ret = Number(element.model.value);
        } else {
          // other
          ret = element.model.value.trim();
        }
      }
      return ret;
    },
    mergeValues(section) {
      const result = [];
      section.forEach((block) => {
        if (Array.isArray(block)) {
          const v = {};
          block.forEach((element) => {
            // e.g. trial_design_group_name_818296 -> to remove the last random number
            const elementId = element.id.substring(0, element.id.lastIndexOf('_'));
            v[elementId] = this.parseValue(element);
          });
          result.push(v);
        } else {
          result.push(this.parseValue(block));
        }
      });
      return result;
    },
    buildInputData() {
      const inputData = {};
      inputData.workflow = this.workflow;
      const elements = Object.values(this.simulation[this.workflow]).flat(Infinity);
      elements.forEach((element) => {
        if (!element.id.startsWith('relapse') && !element.id.startsWith('treatment') && !element.id.startsWith('age_of_onset_ratio') && !element.id.startsWith('trial_design') && !element.id.startsWith('interim')) {
          inputData[element.id] = this.parseValue(element);
        }
      });
      if (this.isWorkflowTrial) {
        if (this.isAgeOfOnsetCustom) {
          inputData.age_of_onset_ratio = this.mergeValues(this.simulation.trial.inclusion[0].filter((element) => element.id.startsWith('age_of_onset_ratio')));
        }
        inputData.trial_design = this.mergeValues(this.simulation.trial.trial_design);
        inputData.interim = this.mergeValues(this.simulation.trial.timeline[1]);
      } else if (this.isWorkflowCalibration) {
        inputData.relapses = this.mergeValues(this.simulation.calibration.history[0]);
        inputData.treatment_history = this.mergeValues(this.simulation.calibration.history[1]);
      } else if (this.isWorkflowPrediction) {
        inputData.virtual_patients = this.virtualPatients;
      }
      // console.debug(inputData);
      return inputData;
    },
    async submitJob() {
      this.isSubmitting = true;
      try {
        const inputData = this.buildInputData();

        if (window.Cypress) {
          // During E2E testing, input data is saved to global window object for retrieval.
          window.testSimulationInputs = inputData;
        }

        if (this.isWorkflowTrial) {
          await this.$apollo.mutate({
            mutation: gql`
            mutation addMstreatTrialJobAndTasks($product_name: ProductName!, $tasks: [MstreatTrialTaskIn]!, $job_name: String!) {
              job: addMstreatTrialJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
                _id
              }
            }`,
            variables: {
              product_name: this.$product.id,
              job_name: this.parseValue(this.title),
              tasks: [inputData],
            },
          });
        } else if (this.isWorkflowCalibration) {
          await this.$apollo.mutate({
            mutation: gql`
            mutation addMstreatCalibrationJobAndTasks($product_name: ProductName!, $tasks: [MstreatCalibrationTaskIn]!, $job_name: String!) {
              job: addMstreatCalibrationJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
                _id
              }
            }`,
            variables: {
              product_name: this.$product.id,
              job_name: this.parseValue(this.title),
              tasks: [inputData],
            },
          });
        } else if (this.isWorkflowPrediction) {
          await this.$apollo.mutate({
            mutation: gql`
            mutation addMstreatPredictionJobAndTasks($product_name: ProductName!, $tasks: [MstreatPredictionTaskIn]!, $job_name: String!) {
              job: addMstreatPredictionJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
                _id
              }
            }`,
            variables: {
              product_name: this.$product.id,
              job_name: this.parseValue(this.title),
              tasks: [inputData],
            },
          });
        }
        this.$router.push('/home');
      } catch (error) {
        console.error(error);
      }
      this.isSubmitting = false;
    },
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    countTokens() {
      this.tokenCounter = this.isWorkflowTrial ? (this.simulation.trial.trial_design.length * 2) : 1;
    },
    validateJob() {
      let valid = true;
      const numberSteps = this.stepLabels.length;
      const stepWarnings = Array(numberSteps).fill(false);

      // validate step 0
      if (!this.title.model.ok) {
        valid = false;
        stepWarnings[0] = true;
      }

      // validate steps 1-N
      Object.values(this.simulation[this.workflow]).forEach((section, index) => {
        section.flat(Infinity).forEach((element) => {
          const check = element.id.startsWith('age_of_onset_ratio') ? this.isAgeOfOnsetCustom : true;
          if (check && element.model.ok != null && !element.model.ok) {
            valid = false;
            stepWarnings[index + 1] = true;
          }
        });
      });

      this.jobValidates = valid;
      this.stepWarnings = stepWarnings;
    },
  },
};
</script>

<style>
.supsub {
  position: relative;
}
.supsub > :first-child {
  position: absolute;
}
p.with-space {
  margin-bottom: 1em !important;
}
button.run-button {
  margin-top: 1em;
}
table.with-margin {
  margin-top: 0.75em;
  margin-bottom: 1.25em;
}
.space-left {
  margin-left: 0.5em;
}
</style>
